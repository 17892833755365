import { to } from "react-spring";

export default [
	{
		title: "Automated Data Wrangling Pipeline",
		description: "A SaaS solution for project management",
		folder: "auto-dwp",
		date: "2018-01",
		percentComplete: 100,
		tags: ["Contract"],
		has_project_details: true,
	},
	{
		title: "Wilderness Survival App",
		description: "A mobile app to track patient vitals in outdoor high-pressure environments",
		folder: "wilderness-survival",
		percentComplete: 100,
		date: { from: "2021-09", to: "2022-04" },
		tags: ["School"]
	},
	{
		title: "Shopify Marketplace Mirror",
		description: "An e-commerce platform for small businesses",
		folder: "shopify",
		percentComplete: 100,
		date: { from: "2020-09", to: "2020-12" },
		tags: ["School"]
	},
	{
		title: "Parkour Map App",
		description: "A social mobile app for parkour enthusiasts",
		folder: "parkour-map",
		percentComplete: 100,
		date: { from: "2020-04", to: "2020-05" },
		tags: ["Startup"]
	},

	{
		title: "MineSweeper AI",
		description: "A deep learning model to predict the next move in a game of MineSweeper",
		folder: "minesweeper",
		percentComplete: 100,
		date: { from: "2020-01", to: "2020-03" },
		tags: ["Learning", "Hobby"]
	},
	{
		title: "Optical Character Recognition",
		description: "A desktop tool to easily extract text from images",
		folder: "ocr",
		percentComplete: 100,
		date: "2020-01",
		tags: ["Learning", "Hobby"]
	},
	{
		title: "TicTacToe Agent",
		description: "A SARSA reinforcement learning model to play TicTacToe",
		folder: "tictactoe",
		percentComplete: 100,
		date: "2019-12",
		tags: ["Learning", "Hobby"]
	},
	{
		title: "VizMotion",
		description: "A tool to generate animations using generative AI",
		folder: "viz-motion",
		percentComplete: 100,
		date: "2024-08",
		tags: ["Hackathon", "Video"],
		video: "https://lablab.ai/event/ibm-watsonx-challenge/recallvault/vizmotion",
		placement: "4th Place"
	},
	{
		title: "Visualizing Diversity",
		description: "A data visualization tool to analyze diversity in the student populations",
		folder: "visualizing-diversity",
		percentComplete: 100,
		date: { from: "2021-05", to: "2022-01" },
		tags: ["Research", "Learning", "Launched"],
		has_project_details: true,
		link: "https://kael558.github.io/EDIProjectPage/"

	},
	{
		title: "Semantic Clustering & Searching Research Papers",
		description: "A tool for semantic clustering and searching research papers",
		folder: "rp_ss",
		percentComplete: 100,
		date: "2022-10",
		tags: ["Hackathon", "Video"],
		has_project_details: true,
		video: "https://lablab.ai/event/transforming-with-transformers/hummingbirds/Research%20Paper%20Clustering",
		placement: "1st Place"

	},
	{
		title: "Stable Diffusion Video Creator",
		description: "A tool integrating SD models to create videos",
		folder: "sd-creator-tool",
		percentComplete: 100,
		date: "2022-11",
		tags: ["Hackathon", "Video"],
		has_project_details: true,
		video: "https://lablab.ai/event/stable-diffusion-hackathon/Fast%20Path/Stable%20Diffusion%20Creator%20Tool",
		placement: "1st Place"
	},
	{
		title: "Automated Annotations with Zero-shot Learning",
		description: "A tool to accelerate the annotation process",
		folder: "auto-annotations",
		percentComplete: 100,
		date: "2022-08",
		tags: ["Learning"],
		has_project_details: true,
	},
	{
		title: "Web Indexer",
		description: "A web page indexer and search engine",
		folder: "web-indexer",
		percentComplete: 100,
		date: "2023-01",
		tags: ["Hackathon", "Video"],
		has_project_details: true,
		video: "https://lablab.ai/event/ai21-labs-hackathon/olympia/webindexer",
		placement: "1st Place"
	},
	{
		title: "Language Learner",
		description: "A tool to analyze the pronunciation of words",
		folder: "language-learner",
		percentComplete: 100,
		date: "2023-07",
		tags: ["Hackathon", "Video"],
		video: "https://lablab.ai/event/eleven-labs-ai-hackathon/phomemes/languagelearner"

	},
	{
		title: "NavBot",
		description: "A voice bot to help visually impaired people navigate the web",
		folder: "navbot",
		percentComplete: 100,
		date: "2023-03",
		tags: ["Hackathon", "Video"],
		video: "https://devpost.com/software/navbot-q3b8k2"
	},
	{
		title: "SmartyPants UI Hackathon",
		description: "Accelerating front-end development with an integrated environment and AI",
		folder: "smartypants-ui",
		percentComplete: 100,
		date: "2024-07",
		tags: ["Hackathon", "Video"],
		video: "https://lablab.ai/event/langflow-hackathon/smartypants-ui/smarty-pants-ui",
		placement: "4th Place"
	},
	{
		title: "Car Retailer ChatBot",
		description: "A chatbot integrated with a car retailer's data to help customers find car parts",
		folder: "tdot-chatbot",
		percentComplete: 100,
		date: { from: "2023-07", to: "2024-04" },
		tags: ["Contract"]
	},
	{
		title: "Detective Game with AI NPCs",
		description: "Chat with AI NPCs to solve a crime",
		folder: "detective-game",
		percentComplete: 100,
		date: { from: "2022-11", to: "2022-12" },
		tags: ["Hobby"]
	},
	{
		title: "AI21 Discord Bot",
		description: "A Discord bot to answer user questions about AI21 Labs",
		folder: "ai21-discord-bot",
		percentComplete: 100,
		date: "2023-07",
		tags: ["Contract"]
	},
	{
		title: "Font Diffusion",
		description: "A tool to generate custom fonts using diffusion models",
		folder: "font-diffusion",
		percentComplete: 100,
		date: "2023-11",
		tags: ["Contract"]
	},
	{
		title: "MoeMate",
		description: "A chatbot website with various characters",
		folder: "moemate",
		percentComplete: 100,
		date: { from: "2023-04", to: "2023-10" },
		tags: ["Contract", "Launched"],
		link: "https://moemate.io"
	},
	{
		title: "PixPal Desktop",
		description: "Interactive characters that assist and react, aware of what's happening on your screen",
		folder: "pixpal-desktop",
		percentComplete: 100,
		date: { from: "2023-11", to: "2024-04" },
		tags: ["Startup", "Launched"],
		link: "https://www.elagonai.com/"
	},
	{
		title: "PixPal Website",
		description: "A chatbot specializing in intimate conversations",
		folder: "pixpal-website",
		percentComplete: 100,
		date: { from: "2024-05", to: "2024-08" },
		tags: ["Startup", "Launched"],
		has_project_details: true,
		link: "https://pixpal.ca"
	},
	{
		title: "SimpleChain",
		description: "Automated software development with modular components",
		folder: "simplechain",
		percentComplete: 80,
		date: { from: "2023-07" },
		tags: ["Hobby"],
		deferred: true,
	},
	{
		title: "Personal AI Assistant",
		description: "An AI assistant available through calling and texting",
		folder: "voice-assistant",
		percentComplete: 100,
		date: { from: "2024-07", to: "2024-10" },
		tags: ["Hobby"],
		has_project_details: true,
	},
	{
		title: "Fluent Future",
		description: "A language learning app for newcomers",
		folder: "fluent-future",
		percentComplete: 90,
		date: { from: "2024-05" },
		tags: ["Startup"],
		has_project_details: true,
		required_investment: 10000,
		next_milestone: {
			title: "Launch",
			date: "2024-10"
		}
	},
	{
		title: "Rapid Launch",
		description: "A template & guide for rapid launching of projects",
		folder: "rapid-launch",
		percentComplete: 50,
		date: { from: "2024-08" },
		tags: ["Startup"],
		has_project_details: true,
		required_investment: 5000,
		deferred: true,
	},
	{
		title: "SmartyPants UI",
		description: "Accelerating front-end development with an integrated environment and AI",
		folder: "smartypants-ui",
		percentComplete: 40,
		date: { from: "2024-07" },
		tags: ["Startup"],
		has_project_details: true,
		required_investment: 10000,
		deferred: true,

	},
	{
		title: "ScriptableAI",
		description: "A web tool to facilitate creation and voicing of scripts",
		folder: "scriptable-ai",
		percentComplete: 80,
		date: { from: "2024-07" },
		tags: ["Startup"],
		has_project_details: true,
		deferred: true,
	},
	{
		title: "Viona Companion",
		description: "A personalized hardware companion for mental support and loneliness",
		folder: "viona",
		percentComplete: 10,
		date: { from: "2024-07" },
		tags: ["Startup"],
		deferred: true,
	},
	{
		title: "Feedback Form JS package",
		description: "A JavaScript package for feedback forms",
		folder: "feedback-form",
		percentComplete: 0,
		date: null,
		tags: ["Startup"],

	},
	{
		title: "Auto Complete Everywhere - Google chrome extension",
		description: "A chrome extension for auto-completion",
		folder: "auto-complete-everywhere",
		percentComplete: 0,
		date: null,
		tags: ["Startup"]
	},
	{
		title: "Commercialized Web Indexer",
		description: "Make any web pages easily searchable beyond key word search",
		folder: "web-indexer-commercialized",
		percentComplete: 100,
		date: { from: "2024-12" },
		tags: ["Startup", "Launched"],
		link: "https://webindexer.app"

	},
	{
		title: "Personal Knowledge Management System",
		description: "A system to manage personal knowledge",
		folder: "knowledge-management",
		percentComplete: 0,
		date: null,
		tags: ["Startup"],
		required_investment: 10000,
	},
	{
		title: "Automated Socials",
		description: "A tool to automate social media posting",
		folder: "auto-socials",
		percentComplete: 0,
		date: null,
		tags: ["Startup"],
		required_investment: 10000,
	},
	{
		title: "Harvard CS50's Web Dev Course",
		description: "A course on web development with Python and JavaScript",
		folder: "cs50-web-dev",
		percentComplete: 100,
		date: { from: "2020-04", to: "2020-05" },
		tags: ["Learning"]
	},
	{
		title: "DataCamp's Data Scientist with Python Course",
		description: "A course on data science with Python",
		folder: "datacamp",
		percentComplete: 100,
		date: { from: "2022-08", to: "2022-09" },
		tags: ["Learning"]
	}
];

